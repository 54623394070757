var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "pt-4 px-6" },
    [
      _vm.trialExpiredBanner
        ? _c("account-banner", {
            staticClass: "mx-3",
            model: {
              value: _vm.accountBannerActive,
              callback: function($$v) {
                _vm.accountBannerActive = $$v
              },
              expression: "accountBannerActive"
            }
          })
        : _vm._e(),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _vm.tabs.length > 1 && _vm.$vuetify.breakpoint.smAndUp
            ? _c(
                "v-tabs",
                {
                  attrs: {
                    "show-arrows": "",
                    grow: "",
                    "background-color": "grey lighten-4"
                  },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                _vm._l(_vm.tabs, function(tab) {
                  return _c(
                    "v-tooltip",
                    {
                      key: tab.id,
                      attrs: {
                        bottom: "",
                        disabled: !tab.disabled,
                        color: "transparent"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "div",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%"
                                        }
                                      },
                                      "div",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-tab",
                                      {
                                        staticStyle: { height: "100%" },
                                        attrs: {
                                          to: tab.path,
                                          exact: "",
                                          disabled: tab.disabled
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(tab.text)) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("vitrue-tooltip", {
                        attrs: {
                          message: _vm.$t("tooltips.viewTeamSettingsAdminOnly")
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            : _vm.tabs.length > 1
            ? _c(
                "v-menu",
                {
                  attrs: { bottom: "", "close-on-click": "", "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { color: "primary" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("account.miniMenuTitle")) +
                                  " "
                              ),
                              _c("v-icon", { staticClass: "ml-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.menuOpen
                                      ? "mdi-chevron-up"
                                      : "mdi-chevron-down"
                                  ) + " "
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.menuOpen,
                    callback: function($$v) {
                      _vm.menuOpen = $$v
                    },
                    expression: "menuOpen"
                  }
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "ma-0 pa-0" },
                    _vm._l(_vm.tabs, function(tab) {
                      return _c(
                        "v-tooltip",
                        {
                          key: tab.id,
                          attrs: {
                            right: "",
                            disabled: !tab.disabled,
                            color: "transparent"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              to: tab.path,
                                              disabled: tab.disabled
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.activeTab = tab
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { attrs: { to: tab.path } },
                                              [_vm._v(_vm._s(_vm.$t(tab.text)))]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: {
                              message: _vm.$t(
                                "tooltips.viewTeamSettingsAdminOnly"
                              )
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card",
            {
              staticClass: "mt-4",
              attrs: {
                flat: "",
                loading: _vm.$route.path === "/account",
                "min-height": "100px"
              }
            },
            [
              _c("v-card-text", { staticClass: "pa-0" }, [_c("router-view")], 1)
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }