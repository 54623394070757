<template>
  <v-row class="pt-4 px-6">
    <account-banner
      class="mx-3"
      v-if="trialExpiredBanner"
      v-model="accountBannerActive"
    />
    <v-col cols="12">
      <v-tabs
        v-if="tabs.length > 1 && $vuetify.breakpoint.smAndUp"
        v-model="activeTab"
        show-arrows
        grow
        background-color="grey lighten-4"
      >
        <v-tooltip
          v-for="tab in tabs"
          :key="tab.id"
          bottom
          :disabled="!tab.disabled"
          color="transparent"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" style="width: 100%; height: 100%">
              <v-tab
                :to="tab.path"
                exact
                :disabled="tab.disabled"
                style="height: 100%"
              >
                {{ $t(tab.text) }}
              </v-tab>
            </div>
          </template>
          <vitrue-tooltip :message="$t('tooltips.viewTeamSettingsAdminOnly')" />
        </v-tooltip>
      </v-tabs>
      <v-menu
        v-else-if="tabs.length > 1"
        bottom
        close-on-click
        offset-y
        v-model="menuOpen"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on">
            {{ $t("account.miniMenuTitle") }}
            <v-icon class="ml-2"
              >{{ menuOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-btn>
        </template>

        <v-list class="ma-0 pa-0">
          <v-tooltip
            v-for="tab in tabs"
            :key="tab.id"
            right
            :disabled="!tab.disabled"
            color="transparent"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-list-item
                  @click="activeTab = tab"
                  :to="tab.path"
                  :disabled="tab.disabled"
                >
                  <v-list-item-title :to="tab.path">{{
                    $t(tab.text)
                  }}</v-list-item-title>
                </v-list-item>
              </div>
            </template>
            <vitrue-tooltip
              :message="$t('tooltips.viewTeamSettingsAdminOnly')"
            />
          </v-tooltip>
        </v-list>
      </v-menu>

      <v-card
        flat
        :loading="$route.path === '/account'"
        min-height="100px"
        class="mt-4"
      >
        <v-card-text class="pa-0">
          <router-view></router-view>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import AccountBanner from "@/components/common/AccountBanner";
import TeamService from "@/services/teamService.js";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  props: {
    tabToShow: String
  },
  data() {
    return {
      activeTab: this.tabToShow ?? "SUBSCRIPTION",
      menuOpen: false,
      accountBannerActive: true
    };
  },
  components: {
    AccountBanner,
    VitrueTooltip
  },
  async created() {
    let memberInfo = await TeamService.getTeamMemberInfo();
    this.setTeamMemberId(memberInfo.teamMemberId);
    if (this.teamMemberId) {
      this.getUserTeamAndSubscriptionBaseInformation({
        memberId: this.teamMemberId,
        forceToUpdateExistedInformation: true
      });
    }

    if (this.$route.path === "/account") {
      this.setActiveTab();
    }
  },
  watch: {
    $route(to, from) {
      // VIDA-1855 - special case where we need to set active tab if user has pressed the settings button in sidebar whilst already being on it.
      // reason: we use nested router view so this component is never re-created / destroyed
      if (
        to.name === "Account" &&
        from.matched.some(record => record.name === "Account")
      ) {
        this.setActiveTab();
      }
    }
  },
  computed: {
    ...mapGetters([
      "teamMemberId",
      "isSimpleUser",
      "currentUserTeamAndSubscriptionBasicInformation",
      "isSuperAdmin",
      "trialExpiredOrAssessmentsExceeded",
      "subscriptionHasExpired"
    ]),
    tabs() {
      let tabs = [];
      if (this.teamMemberId) {
        tabs.push({
          id: "SUBSCRIPTION",
          text: "account.tabTitle1",
          path: "/account/subscription",
          disabled: !this.isSuperAdmin
        });
        tabs.push({
          id: "TEAM",
          text: "account.tabTitle2",
          path: "/account/team",
          disabled: false
        });
      }

      tabs.push({
        id: "PERSONALSETTINGS",
        text: "account.tabTitle3",
        path: "/account/personal-settings",
        disabled: false
      });

      return tabs;
    },
    trialExpiredBanner() {
      return (
        (this.trialExpiredOrAssessmentsExceeded ||
          this.subscriptionHasExpired) &&
        this.activeTab !== "/account/subscription" &&
        !this.isSimpleUser &&
        this.accountBannerActive === true
      );
    }
  },
  methods: {
    ...mapActions(["getUserTeamAndSubscriptionBaseInformation"]),
    ...mapMutations(["setTeamMemberId"]),
    setActiveTab() {
      // if regular end user set tab to be personal settings
      if (this.isSimpleUser || !this.teamMemberId) {
        this.$router.push("/account/personal-settings", () => {});
        return;
      }

      // if regular admin always set tab to be personal settings
      if (!this.isSuperAdmin) {
        this.$router.push("/account/personal-settings", () => {});
        return;
      }

      if (this.$route.path === "/account") {
        this.$router.push("/account/team", () => {});
      }
    }
  }
};
</script>
